import React, {useEffect, useRef, useState} from 'react';

import Welcome from '../components/monitor/welcome';
import Rules from '../components/monitor/rules';
import Playing from '../components/monitor/playing';
import Winner from '../components/monitor/winner';
import {LanguageProvider, useTranslation} from '../components/shared/translator';
import backgroundVideo from '../animations/background_video.mp4';

export default function Monitor() {
    const videoRef = useRef(null); // Reference for the video element
    const [gameState, setGameState] = useState({});

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                // Fetching the sjoelbarService.js:3001/state endpoint
                const response = await fetch(`http://${window.location.hostname}:3001/state`);
                const data = await response.json();
                setGameState(data);
            } catch (error) {
                console.log(error);
            }

        }, 500);
        return () => clearInterval(interval);
    }, []);

    const TranslateString = ({stringKey}) => {
        const {translate} = useTranslation();
        return <span>{translate(stringKey)}</span>;
    };

    return (
        <LanguageProvider>
            <video id="canvas-bg" ref={videoRef} width="1920" height="1080" loop autoPlay muted>
                <source src={backgroundVideo} type="video/mp4"/>
            </video>
            <div className="monitor">
                <div>
                    {gameState.step != "playing" && gameState.step != "winner" && !gameState.game ?
                        <Welcome gameState={gameState}/> : ""}
                    {gameState.step != "playing" && gameState.step != "winner" && gameState.game ?
                        <Rules gameState={gameState}/> : ""}
                    {gameState.step == "playing" ? <Playing gameState={gameState}/> : ""}
                    {gameState.step == "winner" ? <Winner gameState={gameState}/> : ""}
                </div>
            </div>
        </LanguageProvider>
    );
}
