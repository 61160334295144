import React, {useRef, useState} from 'react';

import Welcome from '../components/tablet/welcome';
import Players from '../components/tablet/players';
import Game from '../components/tablet/game';
import Rules from '../components/tablet/rules';
import Playing from '../components/tablet/playing';
import Winner from '../components/tablet/winner';
import Settings from '../components/tablet/settings';
import { LanguageProvider, useTranslation, TranslateString } from '../components/shared/translator';
import backgroundVideo from '../animations/background_video.mp4';

export default function Tablet() {
  const videoRef = useRef(null); // Reference for the video element

  const defaultGameState = {
    step: 'welcome',
    players: [
      { name: 'Player 1', score: [] },
      { name: 'Player 2', score: [] },
    ],
    currentPlayer: 0,
    currentRound: 0,
    game: '',
  }

  const [gameState, setGameState] = useState(defaultGameState);

  // Callback function to update gameState
  const updateGameState = (newGameState) => {
    if (!newGameState) {
      // Reset gameState
      newGameState = defaultGameState;
    }
    // Update gameState
    setGameState((prevGameState) => ({ ...prevGameState, ...newGameState }));

    // Relay game state to sjoelbarService.js:3001/state endpoint using HTTP POST
    fetch(`http://${window.location.hostname}:3001/state`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newGameState)
    })
      .catch(error => console.log(error));
  };

  const LanguageSwitcher = () => {
    const { language, setLanguage } = useTranslation();

    const handleLanguageChange = (lang) => {
      setLanguage(lang);
      localStorage.setItem('language', lang);
    };

    return (
        <div className="language-switch">
          <span className="label"><TranslateString stringKey="select-language"/></span>
          <div>
            <button onClick={() => handleLanguageChange('en')} className={ language === 'en' ? 'puck-btn active' : 'puck-btn' }>
              <span>
                <img src="/img/icons/uk.svg" />
                English
              </span>
            </button>
            <button onClick={() => handleLanguageChange('nl')} className={ language === 'nl' ? 'puck-btn active' : 'puck-btn' }>
              <span>
                <img src="/img/icons/nl.svg" />
                <span>Nederlands</span>
              </span>
            </button>
          </div>
        </div>
    );
  };

  return (
      <LanguageProvider>
        <video id="canvas-bg" ref={videoRef} width="1920" height="1080" loop autoPlay muted>
          <source src={backgroundVideo} type="video/mp4"/>
        </video>
        <div className="tablet">
          {gameState.step == "welcome" ? <LanguageSwitcher/> : ""}
          <div>
            {gameState.step == "welcome" ? <Welcome gameState={gameState} updateGameState={updateGameState} /> : ""}
            {gameState.step == "players" ? <Players gameState={gameState} updateGameState={updateGameState} /> : ""}
            {gameState.step == "game" ? <Game gameState={gameState} updateGameState={updateGameState} /> : ""}
            {gameState.step == "rules" ? <Rules gameState={gameState} updateGameState={updateGameState} /> : ""}
            {gameState.step == "playing" ? <Playing gameState={gameState} updateGameState={updateGameState} /> : ""}
            {gameState.step == "winner" ? <Winner gameState={gameState} updateGameState={updateGameState} /> : ""}
            {gameState.step == "settings" ? <Settings gameState={gameState} updateGameState={updateGameState} /> : ""}
          </div>
        </div>
      </LanguageProvider>
  );
}
