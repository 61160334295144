import Scoreboard from '../shared/scoreboard';

import { gameTypes, getWinnerName, getWinnerScore } from '../../shared/gameTypes';
import {TranslateString} from "../shared/translator";

export default function Winner(props) {

  const { gameState } = props;

  return (
    <div className="content monitor-score-screen">

      <h2 className="pb-3">
          <TranslateString stringKey="Your Game Is Finished"/>
      </h2>

      <h2 className="pb-4">
          <TranslateString stringKey="The winner is"/> <span className='text-warning'>{getWinnerName(gameState)}</span> <TranslateString stringKey="with total score"/> <span className='text-warning'>{getWinnerScore(gameState)}</span> !
      </h2>

      <Scoreboard gameState={gameState} />

  </div>

  );

}
