import React from "react";
import {getGameType, getWinnerId, isGameEnded} from '../../shared/gameTypes';
import {TranslateString} from "./translator";

export default function Scoreboard(props) {
    const {gameState} = props;

    const isCurrentPlayer = (index) => {
        if (isGameEnded(gameState)) {
            return index === getWinnerId(gameState);
        } else {
            return gameState.currentPlayer === index;
        }
    };

    return (
        <table className="scoreboard">
            <thead>
            <tr>
                <th className="player-name">{/* explicitly empty */}</th>
                {gameState.players && gameState.players.map((player, index) => (
                    <th key={`player-${index}`}
                        className={`player-name ${isCurrentPlayer(index) ? 'current-player' : ''}`}>
                        {player.name}
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {gameState.currentRound &&
                [...Array(getGameType(gameState.game).rounds(gameState)).keys()].map((round, i) => (
                    <tr key={`round-${i}`}>
                        <td className="round-label">
                            <TranslateString stringKey="round"/> {i + 1}
                        </td>
                        {gameState.players && gameState.players.map((player, index) => (
                            <td key={`round-${i}-player-${index}`}
                                className={`score ${isCurrentPlayer(index) ? 'current-player' : ''} 
                                ${(isCurrentPlayer(index) && round === gameState.currentRound - 1 && !isGameEnded(gameState) ? 'current-round' : '')}`}>
                                {player.score[i]}
                            </td>
                        ))}
                    </tr>
                ))
            }
            <tr key="total">
                <td className="round-label"><TranslateString stringKey="total"/></td>
                {gameState.players && gameState.players.map((player, index) => (
                    <td key={`total-player-${index}`}
                        className={`score ${isCurrentPlayer(index) ? 'current-player' : ''}`}>
                        {getGameType(gameState.game).getTotalScore(gameState, player)}
                    </td>
                ))}
            </tr>
            </tbody>
        </table>
    );
}
