export const gameTypes = [
    // {
    //     name: "RapiDiscs",
    //     title: "Rapi Discs",
    //     logo: "./img/logos/rapidiscs.png",
    //     highlights: ["30 discs", "1 turn", "3 rounds", "Highest score wins"],
    //     footer: "Expected duration: 5 minutes per player",
    //     rules: [
    //         {
    //             img: "./img/rules/GatherDiscsFromBasket.jpg",
    //             text: "Gather all 30 discs by getting the case from the back of the board and sliding back the not scored discs.",
    //             summary: "Gather all 30 discs"
    //         },
    //         {
    //             img: "./img/rules/StackedDiscs.jpg",
    //             text: "Stack the 30 discs close at the front of the DigiSjoelboard, so you can get them easily while running your turn.",
    //             summary: "Stack the discs at the front of the board"
    //         },
    //         {
    //             img: "./img/rules/ThrowingDisc.jpg",
    //             text: "Slide all 30 discs one by one into the score counters.\nTip: You can use the next discs to slide the previous one into the pocket.",
    //             summary: "Slide all your discs"
    //         },
    //         {
    //             img: "./img/rules/DiscsOnBoard.jpg",
    //             text: "After sliding the 30 discs your round is over. We call this 1 turn.",
    //             summary: "Round is over after sliding all your discs"
    //         },
    //         {
    //             img: "./img/rules/NextPlayer.png",
    //             text: "Tap Next player on the tablet. This new player will follow the rules starting from 1 again.",
    //             summary: "Next player"
    //         },
    //         {
    //             img: "./img/rules/FinishGame.png",
    //             text: "When every player has played 3 rounds the game is over. Tap Finish games. To find out the overall score and the winner.",
    //             summary: "Finish game"
    //         }
    //     ],
    //     description: "The name of the game will give you already some info. This is a very Rapido Game.\nThe game is popular because of its quick changes in players and social connection with your competitors. Who will score the best in this quick game.",
    //     bonus: "Each pocket has a different score. For every complete Row you will get 10 points extra. Remember to complete your rows for higher scores.",
    //     rounds: (gameState) => { return 3; },
    //     getCurrentScore: (player, score, history) => {
    //         return score;
    //     },
    //     getTotalScore: (gameState, player) => {
    //         return player.score.reduce((a, b) => a + b, 0);
    //     },
    //     isGameEnded: (gameState) => {
    //         // Game ends when all players have played their rounds
    //         return false;
    //     },
    //     getWinner: (gameState) => {
    //         // Return the index of the player with the highest score
    //         return gameState.players.findIndex(player => player.score.reduce((a, b) => a + b, 0) == Math.max(...gameState.players.map(player => player.score.reduce((a, b) => a + b, 0))));
    //     }
    // },
    {
        name: "Classic",
        title: "Classic Game",
        logo: "./img/logos/classic.png",
        highlights: [/*"30 discs", "3 turns", "3 rounds", */"The highest score wins"],
        footer: "Expected duration: 15 minutes per player",
        rules: [
            {
                img: "./img/rules/GatherDiscsFromBasket.jpg",
                text: "Gather all 30 discs by getting the case from the back of the board and sliding back the not scored discs.",
                summary: "Gather all 30 discs"
            },
            {
                img: "./img/rules/StackedDiscs.jpg",
                text: "Stack the 30 discs close at the front of the DigiSjoelboard, so you can get them easily while running your turn.",
                summary: "Stack the discs at the front of the board"
            },
            {
                img: "./img/rules/ThrowingDisc.jpg",
                text: "Slide all 30 discs one by one into the score counters.\nTip: You can use the next discs to slide the previous one into the pocket.",
                summary: "Slide all your discs"
            },
            {
                img: "./img/rules/DiscsOnBoard.jpg",
                text: "After sliding the 30 discs your first turn is over. We call this 1 turn.",
                summary: "Round is over after sliding all your discs"
            },
            {
                img: "./img/rules/GatherDiscsFromBoard.jpg",
                text: "Gather the remaining discs from the board and start your second turn with the remaining discs.",
                summary: "Gather the remaining discs"
            },
            {
                img: "./img/rules/DiscsOnBoard2.jpg",
                text: "When you have played your 2nd and 3rd turn, your round is over.",
                summary: "You get two more shots to slide your discs"
            }
            // {
            //     img: "./img/rules/NextPlayer.png",
            //     text: "Tap Next player on the tablet. This new player will follow the rules starting from 1 again. ",
            //     summary: "Next player"
            // },
            // {
            //     img: "./img/rules/FinishGame.png",
            //     text: "When every player has played 3 rounds the game is over. Tap Finish games. To find out the overall score and the winner.",
            //     summary: "Finish game"
            // }
        ],
        description: "This game is a real tradition in Holland. It’s the most common used Sjoelgame. So therefor we call it the classic game. You will receive 30 discs, 3 turns and 3 rounds. The Highest overall score wins.\nWith only one bad turn you have plenty discs to recover and make it your new personal best.",
        bonus: "Each pocket has a different score. For every complete Row you will get 10 points extra. Remember to complete your rows for higher scores.",
        rounds: (gameState) => { return 3; },
        getCurrentScore: (player, score, history) => {
            return score;
        },
        getTotalScore: (gameState, player) => {
            return player.score.reduce((a, b) => a + b, 0);
        },
        isGameEnded: (gameState) => {
            // Game ends when all players have played their rounds
            return false;
        },
        getWinner: (gameState) => {
            // Return the index of the player with the highest score
            return gameState.players.findIndex(player => player.score.reduce((a, b) => a + b, 0) == Math.max(...gameState.players.map(player => player.score.reduce((a, b) => a + b, 0))));
        }
    },
    {
        name: "Countdown",
        title: "Countdown (301)",
        logo: "./img/logos/countdown.png",
        highlights: [/*"30 discs", "Start with 301 points", "As many rounds as needed",*/ "First to zero wins"],
        footer: "Average game time 15 min p.p.",
        rules: [
            {
                img: "./img/rules/GatherDiscsFromBasket.jpg",
                text: "Gather all 30 discs by getting the case from the back of the board and sliding back the not scored discs.",
                summary: "Gather all 30 discs"
            },
            {
                img: "./img/rules/StackedDiscs.jpg",
                text: "Stack the 30 discs close at the front of the DigiSjoelboard, so you can get them easily while running your turn.",
                summary: "Stack the discs at the front of the board"
            },
            {
                img: "./img/rules/ThrowingDisc.jpg",
                text: "Slide all 30 discs one by one into the score counters.\nTip: You can use the next discs to slide the previous one into the pocket.",
                summary: "Slide all your discs"
            },
            {
                img: "./img/rules/DiscsOnBoard.jpg",
                text: "After sliding the 30 discs your first turn is over. We call this 1 turn.",
                summary: "Round is over after sliding all your discs"
            },
            {
                img: "./img/rules/GatherDiscsFromBoard.jpg",
                text: "Gather the remaining discs from the board and start your second turn with the remaining discs.",
                summary: "Gather the remaining discs"
            },
            {
                img: "./img/rules/DiscsOnBoard2.jpg",
                text: "When you have played your 2nd and 3rd turn, your round is over.",
                summary: "You get two more shots to slide your discs"
            }
            // {
            //     img: "./img/rules/NextPlayer.png",
            //     text: "Tap Next player on the tablet. This new player will follow the rules starting from 1 again. ",
            //     summary: "Next player"
            // },
            // {
            //     img: "./img/rules/FinishGame.png",
            //     text: "When a player has score the correct score to finish on zero the game is ended.",
            //     summary: "Finish game"
            // }
        ],
        description: "This game is related to the Dart game 501 which is very much known by each sportsfan. You start with 301 points and we are counting down to zero. May the first to zero win the game. It’s that simple. But be aware. You have to slide the right score to get on zero exactly.\nIn this game you will have 30 discs, 3 turns and as much rounds as you need.\n\nThe starting player has an advantage. Not fair? Spin a disc on the table to find out who can start.",
        bonus: "Each pocket has a different score. For every complete Row you will get 10 points extra. Remember to complete your rows for higher scores.",
        rounds: (gameState) => {
            if (getGameType(gameState.game).isGameEnded(gameState)) {
                return gameState.currentRound - 1;
            }
            else {
                return gameState.currentRound + 1;
            }
        },
        getCurrentScore: (player, score, history) => {
            var currentScore = (player.score.length == 0 ? 301 : player.score[player.score.length - 1]) - score;
            if (currentScore >= 0) {
                return currentScore;
            }
            else {
                var calculateScore = () => {
                    var ports = tempHistory.reduce((obj, num) => (obj[num] = (obj[num] || 0) + 1, obj), { 2: 0, 3: 0, 4: 0, 1: 0 });
                    var lowest = Math.min(...Object.values(ports));
                    return (lowest * 20) + ((ports[2] - lowest) * 2) + ((ports[3] - lowest) * 3) + ((ports[4] - lowest) * 4) + ((ports[1] - lowest) * 1);
                }
                // Go over the history and add the score one by one. When the score goes negative, ignore the last item and continue with the next one.
                var tempHistory = [];
                for (var i = 0; i < history.length; i++) {
                    tempHistory.push(history[i]);
                    currentScore = (player.score.length == 0 ? 301 : player.score[player.score.length - 1]) - calculateScore();
                    if (currentScore < 0) {
                        // Remove the item that caused the score to go negative
                        tempHistory.pop();
                    }
                }
                currentScore = (player.score.length == 0 ? 301 : player.score[player.score.length - 1]) - calculateScore();
                return currentScore;
            }
        },
        getTotalScore: (gameState, player) => {
            return (player.score.length == 0 ? 301 : player.score[player.score.length - 1]);
        },
        isGameEnded: (gameState) => {
            // Game ends when a player has reached zero
            return gameState.players.some(player => (player.score.length == 0 ? 301 : player.score[player.score.length - 1]) == 0);
        },
        getWinner: (gameState) => {
            // Return the index of the player with the _lowest_ score
            return gameState.players.findIndex(player => (player.score.length == 0 ? 301 : player.score[player.score.length - 1]) == Math.min(...gameState.players.map(player => (player.score.length == 0 ? 301 : player.score[player.score.length - 1]))));
        }
    },
    // {
    //     name: "WorldCup",
    //     title: "World Cup",
    //     logo: "./img/logos/worldcup.png",
    //     highlights: ["20 discs", "2 turns", "Highest score wins round", "First to 2 rounds"],
    //     footer: "Expected duration: 15 minutes per player",
    //     rules: [
    //         {
    //             img: "./img/rules/GatherDiscsFromBasket.jpg",
    //             text: "Gather 20 discs by getting the case from the back of the board and sliding back the not scored discs. It's correct if you have 10 discs in the case.",
    //             summary: "Gather 20 discs"
    //         },
    //         {
    //             img: "./img/rules/StackedDiscs.jpg",
    //             text: "Stack the 20 discs close at the front of the DigiSjoelboard, so you can get them easily while running your turn.",
    //             summary: "Stack the discs at the front of the board"
    //         },
    //         {
    //             img: "./img/rules/ThrowingDisc.jpg",
    //             text: "Slide all 20 discs one by one into the score counters.\nTip: You can use the next discs to slide the previous one into the pocket.",
    //             summary: "Slide all your discs"
    //         },
    //         {
    //             img: "./img/rules/DiscsOnBoard.jpg",
    //             text: "After sliding the 20 discs your first turn is over. We call this 1 turn.",
    //             summary: "Round is over after sliding all your discs"
    //         },
    //         {
    //             img: "./img/rules/GatherDiscsFromBoard.jpg",
    //             text: "Gather the remaining discs from the board and start your second turn with the remaining discs.",
    //             summary: "Gather the remaining discs"
    //         },
    //         {
    //             img: "./img/rules/DiscsOnBoard2.jpg",
    //             text: "When you have played your 2nd and 3rd turn, your round is over.",
    //             summary: "You get two more shots to slide your discs"
    //         }
    //         // {
    //         //     img: "./img/rules/NextPlayer.png",
    //         //     text: "Tap Next player on the tablet. This new player will follow the rules starting from 1 again.",
    //         //     summary: "Next player"
    //         // },
    //         // {
    //         //     img: "./img/rules/FinishGame.png",
    //         //     text: "The game will finish when a player reaches two winning rounds. Check the overall score and the winner.",
    //         //     summary: "Finish game"
    //         // }
    //     ],
    //     description: "This game is being played on the World Cup of Sjoelen. Surprised there is a World Cup? The first World Cup was held in 1976 and since then a lot of Dutch Man and Woman were crowned as World Champion, but the competition from other country’s are getting stronger each year. Maybe you are the next World Champion?\n\nIn this World Cup game you will receive 20 discs, 2 turns and you will play first to 2 rounds. When your score in round 1 is higher than your opponents in round 1, You win Round 1. If your opponent’s score in round 2 is better than yours in round 2, he wins a round. Until one of players has won 2 rounds.",
    //     bonus: "Each pocket has a different score. For every complete Row you will get 10 points extra. Remember to complete your rows for higher scores.",
    //     rounds: (gameState) => {
    //         // When the game is ended, the number of rounds is the number of rounds played. Otherwise, the maximum number of rounds is the number of players plus one.
    //         if (getGameType(gameState.game).isGameEnded(gameState)) {
    //             return gameState.currentRound - 1;
    //         }
    //         else {
    //             return gameState.players.length + 1;
    //         }
    //     },
    //     getCurrentScore: (player, score, history) => {
    //         return score;
    //     },
    //     getTotalScore: (gameState, player) => {
    //         // Count how many rounds the current player has won by comparing the player's score per round against the other player scores per round. If the current player has the highest score of that round, add 1 to the total score.
    //         var totalScore = 0;
    //         for (var i = 0; i < player.score.length; i++) {
    //             // Check if all players have a score for the current round
    //             if (gameState.players.every(p => p.score.length > i)) {
    //                 // Calculate high score
    //                 var highestScore = gameState.players.map(p => p.score[i]).reduce((a, b) => Math.max(a, b));
    //                 if (highestScore == player.score[i]) {
    //                     totalScore++;
    //                 }
    //             }
    //         }
    //         return totalScore;
    //     },
    //     isGameEnded: (gameState) => {
    //         // Game ends when the first player has won two rounds
    //         var roundsRequiredToWin = 2;
    //         return gameState.players.some(player => getGameType(gameState.game).getTotalScore(gameState, player) == roundsRequiredToWin);
    //     },
    //     getWinner: (gameState) => {
    //         // Return the index of the player with the highest score
    //         return gameState.players.findIndex(player => player.score.reduce((a, b) => a + b, 0) == Math.max(...gameState.players.map(player => player.score.reduce((a, b) => a + b, 0))));
    //     }
    // }
]

export function getGameType(gameName) {
    return gameTypes.find(game => game.name == gameName);
}

export function getWinnerId(gameState) {
    return getGameType(gameState.game).getWinner(gameState);
}

export function getWinnerName(gameState) {
    return gameState.players[getGameType(gameState.game).getWinner(gameState)].name;
}

export function getWinnerScore(gameState) {
    var winner = gameState.players[getWinnerId(gameState)];
    return getGameType(gameState.game).getTotalScore(gameState, winner);
}

export function isGameEnded(gameState) {
    // Stop the game after the last round
    if (gameState.currentRound > getGameType(gameState.game).rounds(gameState)) {
        return true;
    }
    // Stop the game if the game type says the game is ended based on the current score
    return getGameType(gameState.game).isGameEnded(gameState);
}
