import React, {useEffect, useRef, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {TranslateString} from '../shared/translator';
import videoData from '../../animations/what_the_puck_logo.webm';

function Welcome(props) {
    const {gameState, updateGameState} = props;
    const [settingsCounter, setSettingsCounter] = useState(0);
    const videoRef = useRef(null); // Reference for the video element

    useEffect(() => {
        const video = videoRef.current;

        if (!video) return;

        const startTime = 4;
        const endTime = 20;
        let isLooping = false;

        const handleTimeUpdate = () => {
            if (video.currentTime >= endTime) {
                // Use a small buffer to ensure smooth transition
                video.currentTime = startTime;
            }
        };

        const loop = () => {
            if (isLooping) {
                handleTimeUpdate();
            }
            requestAnimationFrame(loop);
        };

        const onPlay = () => {
            isLooping = true;
            requestAnimationFrame(loop);
        };

        const onPause = () => {
            isLooping = false;
        };

        // Add event listeners
        video.addEventListener('play', onPlay);
        video.addEventListener('pause', onPause);

        // Initial setup
        if (video.readyState >= 2) { // Check if video is ready
            onPlay();
        } else {
            video.addEventListener('canplay', onPlay);
        }

        // Cleanup function to remove event listeners
        return () => {
            video.removeEventListener('play', onPlay);
            video.removeEventListener('pause', onPause);
        };
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        gameState.step = "players";
        updateGameState(gameState);
    };

    const handleSjoelClick = async (num) => {
        if (num === settingsCounter + 1) {
            setSettingsCounter(num);
            if (num === 5) {
                gameState.step = "settings";
                updateGameState(gameState);
            }
        } else {
            setSettingsCounter(0);
        }
    };

    return (
        <div className="content content-welcome">
            <video ref={videoRef} width="650" height="650" autoPlay muted onClick={() => handleSjoelClick(3)}>
                <source src={videoData} type="video/webm"/>
            </video>

            <div className="menu-container">
                <Form onSubmit={handleSubmit} className="navigator">
                    <button type="submit" className="puck-btn">
                        <TranslateString stringKey="start-game"/>
                    </button>
                </Form>
            </div>
        </div>
    );
}

export default Welcome;
