import React, {useEffect, useRef} from 'react';
import videoData from '../../animations/what_the_puck_logo.webm';

function Welcome(props) {
    const videoRef = useRef(null); // Reference for the video element

    useEffect(() => {
        const video = videoRef.current;

        if (!video) return;

        const startTime = 4;
        const endTime = 20;
        let isLooping = false;

        const handleTimeUpdate = () => {
            if (video.currentTime >= endTime) {
                // Use a small buffer to ensure smooth transition
                video.currentTime = startTime;
            }
        };

        const loop = () => {
            if (isLooping) {
                handleTimeUpdate();
            }
            requestAnimationFrame(loop);
        };

        const onPlay = () => {
            isLooping = true;
            requestAnimationFrame(loop);
        };

        const onPause = () => {
            isLooping = false;
        };

        // Add event listeners
        video.addEventListener('play', onPlay);
        video.addEventListener('pause', onPause);

        // Initial setup
        if (video.readyState >= 2) { // Check if video is ready
            onPlay();
        } else {
            video.addEventListener('canplay', onPlay);
        }

        // Cleanup function to remove event listeners
        return () => {
            video.removeEventListener('play', onPlay);
            video.removeEventListener('pause', onPause);
        };
    }, []);

    return (
        <div className="video-container">
            <video ref={videoRef} width="650" height="650" loop autoPlay muted>
                <source src={videoData} type="video/webm"/>
            </video>
        </div>
    );
}

export default Welcome;

