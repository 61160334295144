import React from 'react';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import Scoreboard from '../shared/scoreboard';
import { getGameType, isGameEnded } from '../../shared/gameTypes';
import {TranslateString} from "../shared/translator";

export default function Playing(props) {
  const { gameState, updateGameState } = props;
  const [score, setScore] = useState(0);
  const [history, setHistory] = useState([]);
  const [resetTimer, setResetTimer] = useState(false);
  const puckmans = [
        'puckman-classic',
        'puckman-countdown',
        'puckman-classic',
        'puckman-countdown',
        'puckman-classic',
        'puckman-countdown',
  ];

  useEffect(() => {
    // Keep fetching the sjoelbarService.js:3001/score endpoint and update the score using setInterval
    const interval = setInterval(() => {
      fetch(`http://${window.location.hostname}:3001/score`)
        .then(response => response.json())
        .then(data => {
          setScore(data.score);
          setHistory(data.history);
        })
        .catch(error => console.log(error));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  const handleNextPlayer = async (event) => {
    event.preventDefault();

    // Reset the timer
    setResetTimer(true);
    setTimeout(() => setResetTimer(false), 0);

    // Update the score of the current player
    var tempScore = getGameType(gameState.game).getCurrentScore(gameState.players[gameState.currentPlayer], score, history);
    gameState.players[gameState.currentPlayer].score.push(tempScore);
    // Select next player
    gameState.currentPlayer = (gameState.currentPlayer + 1) % gameState.players.length;
    if (gameState.currentPlayer == 0) {
      gameState.currentRound = gameState.currentRound + 1;
    }

    // Stop when the game has a winner
    if (isGameEnded(gameState)) {
      gameState.step = "winner";
    }

    // Reset the score by calling sjoelbarService.js:3001/reset endpoint
    await fetch(`http://${window.location.hostname}:3001/reset`);

    updateGameState(gameState);
  };

  function isLastRound() {
    return gameState.currentRound == getGameType(gameState.game).rounds(gameState);
  }
  function isLastRoundAndLastPlayer() {
    return gameState.currentRound == getGameType(gameState.game).rounds(gameState) && gameState.currentPlayer == gameState.players.length - 1;
  }
  function isLastPlayer() {
    return gameState.currentPlayer == gameState.players.length - 1;
  }

  const handleGameRules = (event) => {
    event.preventDefault();
    gameState.step = "rules";
    updateGameState(gameState);
  };

  const handleStopGame = async (event) => {
    event.preventDefault();
    updateGameState(null); // Reset
    // Reset the score by calling sjoelbarService.js:3001/reset endpoint
    await fetch(`http://${window.location.hostname}:3001/reset`);
  };

  return (
    <div className="content content-playing">
      <div className="top-controls right">
        <button className="puck-btn" onClick={handleGameRules}>
          <TranslateString stringKey="game-rules" />
        </button>
        <button className="puck-btn" onClick={handleStopGame}>
          <TranslateString stringKey="stop-game" />
        </button>
      </div>

      <h2>{getGameType(gameState.game).title}</h2>

      <img src={`/img/${puckmans[gameState.currentPlayer]}.svg`} />

      <span className="motivation-message"><TranslateString stringKey="show-them-what-youve-got"/></span>
      <h1 className="current-player">
        <span>{gameState.players[gameState.currentPlayer].name}</span>
      </h1>

      <div className="navigator">
        <button className="puck-btn" onClick={handleNextPlayer}>
          <span>
            {isLastRound() && isLastPlayer() ? <TranslateString stringKey="finish-game" /> : <TranslateString stringKey="next-up" /> }
            {!isLastRoundAndLastPlayer() ? (gameState.players[gameState.currentPlayer + 1] !== undefined ?
                    gameState.players[gameState.currentPlayer + 1].name + '!' : gameState.players[0].name  + '!') : ''}
          </span>
        </button>
      </div>
    </div>
  );
}


const Timer = ({ reset }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    // Function to update the timer every second
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [reset]);

  useEffect(() => {
    if (reset) {
      // Reset the timer by setting seconds back to 0
      setSeconds(0);
    }
  }, [reset]);

  // Calculate minutes and remaining seconds
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return (
    <>
      {minutes.toString().padStart(2, '0')}:{remainingSeconds.toString().padStart(2, '0')}
    </>
  );
}

