import {Button} from 'react-bootstrap';
import React, {useRef} from "react";
import backgroundVideo from "../animations/background_video.mp4";
import videoData from "../animations/what_the_puck_logo.webm";

export default function App() {
    const videoRef = useRef(null); // Reference for the video element
    const backgroundVideoRef = useRef(null); // Reference for the video element

    return (
        <div>
            <video id="canvas-bg" ref={backgroundVideoRef} width="1920" height="1080" loop autoPlay muted>
                <source src={backgroundVideo} type="video/mp4"/>
            </video>

            <div className="content">
                <div className="video-container">
                    <video ref={videoRef} width="650" height="650" autoPlay muted>
                        <source src={videoData} type="video/webm"/>
                    </video>
                </div>

                <div className="d-flex gap-4">
                    <Button variant="secondary" className="puck-btn" size="lg" href="/tablet">
                        <span>Tablet</span>
                    </Button>
                    <Button variant="secondary" className="puck-btn" size="lg" href="/monitor">
                        <span>Monitor</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}
