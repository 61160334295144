import React, {useEffect, useState} from 'react';

import Scoreboard from '../shared/scoreboard';
import {getGameType} from '../../shared/gameTypes';
import {TranslateString} from "../shared/translator";

import port2img from './2.svg';
import port3img from './3.svg';
import port4img from './4.svg';
import port1img from './1.svg';

export default function Playing(props) {

    const {gameState} = props;
    let running = 0;

    const [score, setScore] = useState(0);
    const [port2, setPort2] = useState(0);
    const [port3, setPort3] = useState(0);
    const [port4, setPort4] = useState(0);
    const [port1, setPort1] = useState(0);
    const [history, setHistory] = useState([]);

    const [portsData, setBonusScoreAnimation] = useState(0);
    const [popupVideoVisible, setPopupVideoVisible] = useState(false);
    const [completedRoundVideoVisible, setCompletedRoundVideoVisible] = useState(false);
    const [videoFile, setVideoFile] = useState('');
    const [completedRoundScore, setCompletedRoundScore] = useState(0);
    const [completedRoundPlayer, setCompletedRoundPlayer] = useState('');

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                // Fetching the sjoelbarService.js:3001/score endpoint
                const response = await fetch(`http://${window.location.hostname}:3001/score`);
                const data = await response.json();

                setScore(data.score);
                setPort2(data.port2);
                setPort3(data.port3);
                setPort4(data.port4);
                setPort1(data.port1);
                setHistory(data.history);
                setBonusScoreAnimation(Math.min(data.port1, data.port2, data.port3, data.port4));

                // Store last score for player round rating animation since it's tricky to fetch after player is switched
                if (data.score > 0) {
                    localStorage.setItem('lastScore', data.score);
                }

            } catch (error) {
                console.log(error);
            }
        }, 500);
        return () => clearInterval(interval);
    }, []);

    var completedRowVideoTimeout;

    useEffect(() => {
        // The bonus animation is shown when a row is completed
        if (portsData > 0) {
            // Show bonus video
            const videoPath = '/video/bonus_' + (Math.floor(Math.random() * 5) + 1) + '.webm';
            setVideoFile(videoPath);
            setPopupVideoVisible(true);

            // Show popup for 5 seconds and clear JS timeout
            completedRowVideoTimeout = setTimeout(() => {
                setPopupVideoVisible(false);
            }, 5 * 1000);

            return () => clearTimeout(completedRowVideoTimeout);
        }
    }, [portsData])

    useEffect(() => {
        // The verdict animation only shows when a players round is finished
        if (gameState.currentRound !== 1 || gameState.currentPlayer !== 0) {
            const lastScore = localStorage.getItem('lastScore') || 0;
            const scoreCategory = lastScore < 80 ? 0 : lastScore <= 120 ? 1 : 2;
            const videoPath = '/video/verdict_' + scoreCategory + '_' + (Math.floor(Math.random() * 5) + 1) + '.webm';

            // Set variables for round text rule (placed over the round verdict animation)
            setCompletedRoundPlayer(gameState.currentPlayer === 0 ? gameState.players[gameState.players.length - 1].name : gameState.players[gameState.currentPlayer - 1].name);
            setCompletedRoundScore(lastScore);

            // Show verdict video
            setVideoFile(videoPath);
            setCompletedRoundVideoVisible(true);

            // Reset lastScore
            localStorage.setItem('lastScore', 0);

            // Show popup for 5 seconds and clear JS timeout
            completedRowVideoTimeout = setTimeout(() => {
                setCompletedRoundVideoVisible(false);
            }, 5 * 1000);

            return () => clearTimeout(completedRowVideoTimeout);
        }
    }, [gameState.currentPlayer])

    useEffect(() => {
        // The epic score animation only shows when port4 is triggered x times
        if (port4 === 3) {
            // Show epic score video
            setVideoFile('/video/verdict_2_2.webm');
            setPopupVideoVisible(true);

            // Show popup for 5 seconds and clear JS timeout
            completedRowVideoTimeout = setTimeout(() => {
                setPopupVideoVisible(false);
            }, 5 * 1000);

            return () => clearTimeout(completedRowVideoTimeout);
        }
    }, [port4])

    useEffect(() => {
        // The hurry up animation only shows when a player has 0 points after X seconds
        const timer = setInterval(() => {
            const lastScore = parseInt(localStorage.getItem('lastScore')) || 0;
            const runningMod = running % 30;

            if (running > 0 && runningMod === 0 && lastScore === 0) {
                // Show hurry up video
                const videoPath = '/video/timeout_' + (Math.floor(Math.random() * 3) + 1) + '.webm';
                setVideoFile(videoPath);
                setPopupVideoVisible(true);

                // Show popup for 5 seconds and clear JS timeout
                const hurryUpPopupTimeout = setTimeout(() => {
                    setPopupVideoVisible(false);
                }, 5 * 1000);
            }

            running++;
        }, 1000);

        return () => clearInterval(timer); // Cleanup on unmount
    }, [gameState.currentPlayer]);

    return (
        <div className="content monitor-score-screen">
            {popupVideoVisible && (
                <div className="popup">
                    <div className="inside">
                        <video width="1920" height="1080" autoPlay muted>
                            <source src={videoFile} type="video/webm"/>
                        </video>
                    </div>
                </div>
            )}

            {completedRoundVideoVisible && (
                <div>
                    <p className="completed-round-points">{completedRoundPlayer} <TranslateString
                        stringKey="your-score-was"/> {completedRoundScore} <TranslateString stringKey="points"/></p>
                    <div className="popup">
                        <div className="inside">
                            <video src={videoFile} width="100%" autoPlay muted></video>
                        </div>
                    </div>
                </div>
            )}

            <div className="monitor-container">
                <h2>
                    <span>{getGameType(gameState.game).title}</span>
                </h2>

                <Scoreboard gameState={gameState}/>
            </div>

            <div className="puck-container">
                <div className="puck-wrapper">
                    <div className="puck-item">
                        <div className="puck-point">
                            <h1>{port2}</h1>
                        </div>
                        <div className="puck-port">
                            <img src={port2img} onClick={() => fetch("http://localhost:3001/mock?action=port2")}/>
                        </div>
                    </div>
                    <div className="puck-item">
                        <div className="puck-point">
                            <h1>{port3}</h1>
                        </div>
                        <div className="puck-port">
                            <img src={port3img} onClick={() => fetch("http://localhost:3001/mock?action=port3")}/>
                        </div>
                    </div>
                    <div className="puck-item">
                        <div className="puck-point">
                            <h1>{port4}</h1>
                        </div>
                        <div className="puck-port">
                            <img src={port4img} onClick={() => fetch("http://localhost:3001/mock?action=port4")}/>
                        </div>
                    </div>
                    <div className="puck-item">
                        <div className="puck-point">
                            <h1>{port1}</h1>
                        </div>
                        <div className="puck-port">
                            <img src={port1img} onClick={() => fetch("http://localhost:3001/mock?action=port1")}/>
                        </div>
                    </div>
                </div>

            </div>

            {/* <pre>{JSON.stringify(gameState, false, 2)}</pre> */}
        </div>
    );

}

const Puck = ({number, min}) => {
    return (
        <div>
            {[...Array(number).keys()].map(i => (
                <div key={i} className={"puck " + (number - i <= min ? "text-bg-warning" : "")}></div>
            ))}
        </div>
    );
}

