import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import KeyboardPopup from '../shared/keyboardpopup';

function Settings(props) {

  const { gameState, updateGameState } = props;

  const [isKeyboardPopupOpen, setIsKeyboardPopupOpen] = useState(false);
  const [keyboardUpdateCallback, setKeyboardUpdateCallback] = useState({callback: null}); // hack

  const [wifiNetworks, setWifiNetworks] = useState([]);
  const [wifiSsid, setWifiSsid] = useState("");
  const [wifiPassword, setWifiPassword] = useState("");
  const [wifiStatus, setWifiStatus] = useState(null);
  const [wifiCurrentNetwork, setWifiCurrentNetwork] = useState(null);
  
  const [currentVersion, setCurrentVersion] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);

  const handleScanWifi = async () => {
    try {
      const response = await fetch(`http://${window.location.hostname}:3001/wifi/scan`);
      const data = await response.json();
      if (data.status != 'ok') {
        console.log(error);
        alert('Error while scanning wifi networks.')
        return;
      }
      setWifiNetworks(data.networks);
    } catch (error) {
      console.log(error);
    }
  }

  const handleWifiNetworkSelect = (network) => {
    setWifiSsid(network.ssid);
  };

  const handleWifiSsidChange = (e) => {
    setWifiSsid(e.value);
  }

  const handleWifiPasswordChange = (e) => {
    setWifiPassword(e.value);
  }

  const handleConnectToWifi = async (e) => {
    const response = await fetch(`http://${window.location.hostname}:3001/wifi/connect`, {
      method: "POST",
      body: JSON.stringify({ssid: wifiSsid, password: wifiPassword}),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (data.status=="error") {
      setWifiStatus("error");
      alert(`Error while connecting: ${data.details}`);
      return;
    }
    setTimeout(async () => {
      const response = await fetch(`http://${window.location.hostname}:3001/wifi/current`);
      const data = await response.json();
      if (data.networks && data.networks.length > 0) {
        setWifiCurrentNetwork(data.networks[0].ssid);
        setWifiStatus("connected");
      }
    }, 1000);
  }

  const handleCheckNewVersion = async(e) => {
    // Get current version
    const response = await fetch(`http://${window.location.hostname}:3001/version/current`);
    const data = await response.json();
    if (data.status != "ok") {
      alert(`Error while getting current version: ${data.details}`);
      return;
    }
    setCurrentVersion(data.version);
    // Get latest version
    const response2 = await fetch(`http://${window.location.hostname}:3001/version/latest`);
    const data2 = await response2.json();
    if (data2.status != "ok") {
      alert(`Error while getting latest version: ${data2.details}`);
      return;
    }
    setLatestVersion(data2.version);
  }

  const handleUpdate = async(e) => {
    if (window.confirm("This will download and install the new version. This may take a few minutes, after which the system will reboot.\n\nAre you sure?")){

      const response = await fetch(`http://${window.location.hostname}:3001/version/update`, {
        method: "POST",
        body: JSON.stringify({version: latestVersion}),
        headers: {
          "Content-Type": "application/json",
        },
      });

      alert("Update started, please wait a few minutes until the system reboots.")
    }
  }

  const keyboardPopupClose = (updatedText) => {
    keyboardUpdateCallback.callback(updatedText);
    setIsKeyboardPopupOpen(false);
  }

  const constHandleKeyboardPopupOpen = (callback) => {
    console.log(callback)
    setKeyboardUpdateCallback({callback: callback});
    setIsKeyboardPopupOpen(true);
  }

  return (
    <div>
      <h2>Wifi</h2>

      {wifiStatus!="connected" && (
        <>
          <div className="d-flex justify-content-center pb-3">
            <Button
              variant='success'
              onClick={(e) => handleScanWifi(e)}
            >
              Scan wifi networks
            </Button>
          </div>

          {wifiNetworks.length > 0 && (
          <div className="d-flex justify-content-center">
            <table className="table table-dark">
                <thead>
                  <tr>
                    <th>SSID</th>
                    <th>Security</th>
                    <th>Quality</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {wifiNetworks.filter(network => network.ssid).map((network, i) => (
                    <tr key={i}>
                      <td>{network.ssid}</td>
                      <td>{network.security}</td>
                      <td>{network.quality}</td>
                      <td>
                        <Button
                          variant='success'
                          onClick={(e) => handleWifiNetworkSelect(network)}
                        >
                          Select
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
            </table>
          </div>
          )}

          <Form.Control
            type="text"
            value={wifiSsid}
            onChange={(e) => handleWifiSsidChange(e)}
            onClick={() => constHandleKeyboardPopupOpen((value) => setWifiSsid(value))}
            required
            placeholder={`SSID`}
          />
          <Form.Control
            type="text"
            value={wifiPassword}
            onChange={(e) => handleWifiPasswordChange(e)}
            onClick={() => constHandleKeyboardPopupOpen((value) => setWifiPassword(value))}
            required
            placeholder={`Password`}
          />

          <div className="d-flex justify-content-center pt-3 pb-3">
            <Button
              variant='success'
              onClick={(e) => handleConnectToWifi(e)}
            >
              Connect to wifi
            </Button>
          </div>
        </>
      )}

      {wifiStatus=="connected" && (
        <div className="d-flex justify-content-center">
          Connected to: {wifiCurrentNetwork}
        </div>
      )}

      <h2>Update</h2>

      <div className="d-flex justify-content-center">
        <Button
          variant='success'
          onClick={(e) => handleCheckNewVersion(e)}
        >
          Check for new version
        </Button>
      </div>

      {currentVersion && latestVersion && (
        <>
          <div className="d-flex justify-content-center">
            <table className="table table-dark">
              <tbody>
                <tr>
                  <td>Current version</td>
                  <td>{currentVersion}</td>
                </tr>
                <tr>
                  <td>Latest version</td>
                  <td>{latestVersion}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {currentVersion != latestVersion && (
            <div className="d-flex justify-content-center">
              <Button
                variant='success'
                onClick={(e) => handleUpdate(e)}
              >
                Update
              </Button>
            </div>
          )}
        </>
      )}


      <KeyboardPopup isOpen={isKeyboardPopupOpen} onClose={keyboardPopupClose} fullKeyboard={true} />

    </div>
  );
}

export default Settings;
