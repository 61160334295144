import React from 'react';

import { gameTypes } from '../../shared/gameTypes';
import {TranslateString} from "../shared/translator";

export default function Rules(props) {

    const { gameState, updateGameState } = props;

    const isPlaying = () => {
        if (gameState.currentPlayer > 0 || gameState.currentRound > 0)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const handleStartGame = async (event) => {
        event.preventDefault();
        gameState.step = "playing";
        gameState.currentRound = 1;
        updateGameState(gameState);
        // Reset the score by calling sjoelbarService.js:3001/reset endpoint
        await fetch(`http://${window.location.hostname}:3001/reset`);
    };

    const handleGoBack = (event) => {
        event.preventDefault();
        if (isPlaying())
        {
            gameState.step = "playing";
        }
        else
        {
            gameState.step = "game";
        }
        updateGameState(gameState);
    };

    return (
        <div className="content content-rules">
            <div className="rules">
                <h2><TranslateString stringKey="game-rules"/></h2>
                <ul>
                    {gameTypes.find(game => game.name == gameState.game).rules.map((rule, i) => <li key={`start-${i}`}><span><TranslateString stringKey="step" /> {i+1}</span><span><TranslateString stringKey={rule.summary} /></span></li>)}
                </ul>
                <img src="/img/puckman-classic.svg" />
            </div>
        </div>
    );
}
