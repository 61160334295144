import React from 'react';
import Button from 'react-bootstrap/Button';

import Scoreboard from '../shared/scoreboard';

import { gameTypes, getWinnerName, getWinnerScore } from '../../shared/gameTypes';
import {TranslateString} from "../shared/translator";

function Winner(props) {

    const { gameState, updateGameState } = props;

    const handlePlayAgain = async (event) => {
        event.preventDefault();

        gameState.step = "playing";
        gameState.currentPlayer = 0;
        gameState.currentRound = 1;
        gameState.players.forEach(player => {
            player.score = [];
        });

        updateGameState(gameState); // Reset

        // Reset the score by calling sjoelbarService.js:3001/reset endpoint
        await fetch(`http://${window.location.hostname}:3001/reset`);

    };

    const handleStartNewGame = async (event) => {
        event.preventDefault();

        gameState.step = "game";
        gameState.currentPlayer = 0;
        gameState.currentRound = 0;
        gameState.players.forEach(player => {
            player.score = [];
        });
        gameState.game = "";

        updateGameState(gameState); // Reset

        // Reset the score by calling sjoelbarService.js:3001/reset endpoint
        await fetch(`http://${window.location.hostname}:3001/reset`);

    };

    const handleStopGame = async (event) => {
        event.preventDefault();
        updateGameState(null); // Reset

        // Reset the score by calling sjoelbarService.js:3001/reset endpoint
        await fetch(`http://${window.location.hostname}:3001/reset`);
    };

    return (
        <div className="content content-winner">
            <h2><TranslateString stringKey="your-game-is-finished" /></h2>
            {/* <h2 classname="pb-3">
                You played: <span className='text-warning'>{gameTypes.find(game => game.name == gameState.game).title}</span>
            </h2> */}
            <span className="winner-message">
                <TranslateString stringKey="the-winner-is"/> <span className="highlight">{getWinnerName(gameState)}</span> <TranslateString stringKey="with-total-score"/> <span className="highlight">{getWinnerScore(gameState)}</span>!
            </span>
            <Scoreboard gameState={gameState} />
            <div className="navigator">
                <button className="puck-btn" onClick={handlePlayAgain}>
                    <TranslateString stringKey="play-again" />
                </button>
                <button className="puck-btn" onClick={handleStartNewGame}>
                    <TranslateString stringKey="start-new-game" />
                </button>
                <button className="puck-btn" onClick={handleStopGame}>
                    <TranslateString stringKey="stop-im-leaving" />
                </button>
            </div>
        </div>
    );
}

export default Winner;
