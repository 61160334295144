import React from 'react';
import Card from 'react-bootstrap/Card';

import { gameTypes } from '../../shared/gameTypes';
import {TranslateString} from "../shared/translator";

function Game(props) {

  const { gameState, updateGameState } = props;

  const handleGameClick = (event) => {
    event.preventDefault();
    gameState.game = event.currentTarget.getAttribute("game");
    updateGameState(gameState);

    handleContinue(event);
  };

  const handleContinue = (event) => {
    event.preventDefault();
    if (!gameState.game) {
      alert("Please select a game type");
      return;
    }
    gameState.step = "rules";
    updateGameState(gameState);
  };

  const handleGoBack = (event) => {
    event.preventDefault();
    gameState.step = "players";
    updateGameState(gameState);
  };

  return (
      <div className="content content-game">
        <div className="top-controls left">
          <button className="puck-btn" onClick={handleGoBack}>
            <span>
              <img src="/img/icons/chevron.svg" />
              <TranslateString stringKey="go-back" />
            </span>
          </button>
        </div>
        <h2><TranslateString stringKey="choose-your-game" /></h2>
        <div className="games">
          {gameTypes.map((game, idx) => (
              <Card key={idx}>
                <Card.Body>
                  <img src={`/img/puckman-${game.name.toLowerCase()}.svg`} />
                  <Card.Title><h2>{game.title}</h2></Card.Title>
                      {/* <Card.Text>{game.description}</Card.Text> */}
                      {/* <ul className="list-unstyled">
                        {game.highlights.map((highlight, i) => <li key={i}>{highlight}</li>)}
                      </ul> */}
                      <div className="text-center">
                        {game.highlights.map((highlight, i) =>
                          <span key={i}><TranslateString stringKey={highlight} />{i + 1 < game.highlights.length ? " • " : "" }</span>
                        )}
                      </div>
                      <div className="navigator">
                          <button className="puck-btn" onClick={handleGameClick} game={game.name}>
                              <span><TranslateString stringKey={`start-${game.name.toLowerCase()}`}/></span>
                          </button>
                      </div>
                </Card.Body>
              </Card>
          ))}
        </div>
    </div>
  );
}

export default Game;
