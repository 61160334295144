import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import KeyboardPopup from '../shared/keyboardpopup';
import {TranslateString} from "../shared/translator";

function Players(props) {

    const {gameState, updateGameState} = props;

    const [numberOfPlayers, setNumberOfPlayers] = useState(gameState.players.length);
    const [playerNames, setPlayerNames] = useState(gameState.players.map(player => player.name));

    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const [isKeyboardPopupOpen, setIsKeyboardPopupOpen] = useState(false);

    const handlePlayerCountChange = (event) => {
        var previousPlayerNames = playerNames;
        const count = parseInt(event.currentTarget.getAttribute('data-count'), 10);  // Use data-count attribute
        setNumberOfPlayers(count);
        // Copy previous player names and extend or reduce the array to match the new number of players
        var newPlayerNames = previousPlayerNames.slice();
        newPlayerNames.length = count;
        newPlayerNames.fill('', previousPlayerNames.length, count);
        // Fill empty names with default names
        newPlayerNames = newPlayerNames.map((name, index) => name || `Player ${index + 1}`);
        setPlayerNames(newPlayerNames);
    };

    const keyboardPopupPlayerNameChange = (updatedText) => {
        const updatedPlayerNames = [...playerNames];
        updatedPlayerNames[selectedPlayer] = updatedText;
        setPlayerNames(updatedPlayerNames);
        setIsKeyboardPopupOpen(false);
    }

    const constHandleKeyboardPopupOpen = (index) => {
        setSelectedPlayer(index);
        setIsKeyboardPopupOpen(true);
    }

    const handlePlayerNameChange = (event, index) => {
        const updatedPlayerNames = [...playerNames];
        updatedPlayerNames[index] = event.target.value;
        setPlayerNames(updatedPlayerNames);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        gameState.step = "game";
        gameState.players = playerNames.map((name) => ({name, score: []}));
        updateGameState(gameState);
    };

    const handleGoBack = (event) => {
        event.preventDefault();
        gameState.step = "welcome";
        updateGameState(gameState);
    };

    return (
        <div className="content content-players player-selection-screen">
            <div className="top-controls">
                <button className="puck-btn" onClick={handleGoBack}>
            <span>
              <img src="/img/icons/chevron.svg"/>
              <TranslateString stringKey="go-back"/>
            </span>
                </button>
            </div>

            <Form onSubmit={handleSubmit}>
                <h2><TranslateString stringKey="how-many-players"/></h2>

                <div className="number-of-players">
                    <Form.Group as={Row}>
                        <Col className="numbers-wrapper">
                            <ButtonGroup>
                                {[2, 3, 4, 5, 6].map((count) => (
                                    <Button
                                        variant={count === numberOfPlayers ? 'success' : 'secondary'}
                                        key={count}
                                        onClick={(e) => handlePlayerCountChange(e)}
                                        data-count={count}>
                                        <span>{count}</span>
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Col>
                    </Form.Group>
                </div>

                <h2><TranslateString stringKey="what-are-your-names"/></h2>

                <div className="name-inputs">
                    {playerNames.map((name, index) => (
                        <Form.Group as={Row} key={index}>
                            <Col>
                                <div className="label">
                                    <Form.Label>Player {index + 1}</Form.Label>
                                </div>
                                <Form.Control
                                    type="text"
                                    value={name}
                                    onChange={(e) => handlePlayerNameChange(e, index)}
                                    onClick={() => constHandleKeyboardPopupOpen(index)}
                                    required
                                    placeholder={`Player ${index + 1}`}
                                />
                            </Col>
                        </Form.Group>
                    ))}
                </div>

                <div className="navigator">
                    <button type="submit" className="puck-btn">
                        <TranslateString stringKey="continue"/>
                    </button>
                </div>

                <KeyboardPopup isOpen={isKeyboardPopupOpen} onClose={keyboardPopupPlayerNameChange} fullKeyboard={false}
                               placeholder={`Player ${selectedPlayer + 1}`}/>

            </Form>
        </div>
    );
}

export default Players;
