import React, { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './keyboardpopup.css';



export default function KeyboardPopup(props) {

  const { isOpen, onClose, fullKeyboard, placeholder } = props;
  
  const [text, setText] = useState("");
  const [layoutName, setLayoutName] = useState("default");

  useEffect(() => {
    if (isOpen) {
      setText("");
    }
  }, [isOpen]);

  var layout = null;

  if (fullKeyboard) {

    layout = {
      default: [
        "` 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
        "q w e r t y u i o p [ ]",
        "a s d f g h j k l ; ' \\",
        "{shift} z x c v b n m , . /",
        "{space}"
      ],
      shift: [
        "~ ! @ # $ % ^ & * ( ) _ +",
        "Q W E R T Y U I O P { }",
        "A S D F G H J K L : \" |",
        "{shift} Z X C V B N M < > ?",
        "{space}"
      ]
    };

  } else {

    layout = {
      default: [
        "q w e r t y u i o p",
        "a s d f g h j k l",
        "z x c v b n m {backspace}",
        "{space}"
      ],
      shift: [
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "{shift} Z X C V B N M {backspace}",
        "{space}"
      ]
    };

  }

  const display = {
    "{numbers}": "123",
    "{space}": " ",
    "{ent}": "enter",
    "{escape}": "esc ⎋",
    "{tab}": "tab ⇥",
    "{backspace}": "⌫",
    "{capslock}": "caps lock ⇪",
    "{shift}": "⇧",
    "{controlleft}": "ctrl ⌃",
    "{controlright}": "ctrl ⌃",
    "{altleft}": "alt ⌥",
    "{altright}": "alt ⌥",
    "{metaleft}": "cmd ⌘",
    "{metaright}": "cmd ⌘",
    "{abc}": "ABC"
  };

  const onChangeInput = (input) => {
    if (!fullKeyboard) {
      // use a regex replace to make every first letter uppercase
      input = input.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
    }
    setText(input);
  };

  const handleInputChange = (e) => {
    onChangeInput(e.target.value);
  };

  const handleOnKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName == "default" ? "shift": "default");
    } else {
      setLayoutName("default");
    }
  }

  const handleConfirm = () => {
    onClose(text);
  }

  return (
    <>
      {isOpen &&
        <div className="keyboardpopupfade">
          <div className="keyboardpopup">

            <Form.Control
                type="text"
                value={text}
                onChange={handleInputChange}
                required
                placeholder={placeholder}
              />
            
            <p />

            <Keyboard
              onChange={onChangeInput}
              layout={layout}
              layoutName={layoutName}
              mergeDisplay={true}
              display={display}
              onKeyPress={handleOnKeyPress}
              theme={"hg-theme-default myTheme1"}
            />
            
            <p />

            <Button variant="success" size="lg" className="next" onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      }
    </>
  );

}